import { ModuleAuthorisationManager } from "@/services/module-authorisation-manager"

export class AdministrationHelper {
  public static GetAdminNavItems () {
    const navItems = [
      {
        icon: 'mdi-office-building-marker-outline',
        title: 'Configuration des salles',
        to: '/administration/rooms',
        order: 4
      },
      {
        icon: 'mdi-file-document-multiple-outline',
        title: 'Gestion des listes',
        to: '/administration/listsManagement',
        order: 6
      }
    ]

    if (ModuleAuthorisationManager.HasAccess('admin.statistics')) {
      navItems.push({
        icon: 'mdi-file-chart-outline',
        title: 'Statistiques',
        to: '/administration/statistics',
        order: 5
      })
    }
    if (ModuleAuthorisationManager.HasAccess('admin.hoursManagementAndExport')) {
      navItems.push({
        icon: 'mdi-account-clock-outline',
        title: 'Gestion des heures saisies',
        to: '/administration/hoursManage',
        order: 3
      })
    }
    if (ModuleAuthorisationManager.HasAccess('admin.userManagement')) {
      navItems.push({
        icon: 'mdi-badge-account-horizontal-outline',
        title: 'Gestion des utilisateurs',
        to: '/administration',
        order: 1
      })
    }
    if (ModuleAuthorisationManager.HasAccess('admin.hoursConfiguration')) {
      navItems.push({
        icon: 'mdi-clipboard-clock-outline',
        title: 'Configuration des heures',
        to: '/administration/hours',
        order: 2
      })
    }
    return navItems.sort((a, b) => a.order - b.order)
  }
}

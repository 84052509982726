import { LayoutService } from '@/services/layout-service'
import { Component, Vue, Watch } from 'vue-property-decorator'
import SoldeDialog from '@/components/shared/Dialogs/SoldeDialog/SoldeDialog.vue'
import Commons from '@/components/shared/Helpers/commons'
import { dossierPatientModel } from '@/models/dossier-response-model'
import Alert from '@/components/shared/Alert.vue'
import { PatientService } from '@/services/patient-service'
import { ErrorService } from '@/services/error.service'
import { HourService } from '@/services/hour-service'
import { HoursResponseModel, HoursSearchModel, UserMonthHoursResponseModel } from '@/models/hours-model'
import { AuthService } from '@/services/auth-service'
import { AppUser } from '@/models/app-user-dto'
import { ValidationObserver } from 'vee-validate'
import HoursHelpers from '@/components/shared/Helpers/hours-helper'
import { PagedResults } from '@/models/common-models'
import { AdministrationHelper } from '../AdministrationHelper'

@Component({
  components: {
    Alert,
    SoldeDialog,
    ValidationObserver
  }
})
export default class HoursManage extends Vue {
  private layoutService = LayoutService.getInstance()
  private patientsService = PatientService.getInstance()
  private hoursService = HourService.getInstance()
  private authService = AuthService.getInstance()

  public apiURL = process.env.VUE_APP_API_URL

  public soldeDialog = false
  public searching = false

  public dbPatients: dossierPatientModel[] = []
  public allUsers: AppUser[] = []
  public rubriques: any[] = []
  public dataHours: PagedResults<HoursResponseModel> = {
    items: [],
    totalSize: 0
  }

  public errorMessages: string[] = []
  private ready = false
  private readyPatients = true
  private readyHours = false
  private readyCategories = false
  private readyUsers = false
  private validating = false
  public searchModel: HoursSearchModel = HoursManage.resetSearchModel()
  private selectedItem: UserMonthHoursResponseModel | any = {}

  public usersSelected = []
  public patientsSelected = []

  public searchPatient = ''

  public searchPeriode = [
    {
      from: new Date().toISOString().substr(0, 7),
      to: new Date().toISOString().substr(0, 7)
    }
  ]

  public rubriquesSelected = []
  public page = 1

  public selected: dossierPatientModel[] = []

  public headersMonth = [
    {
      text: 'Mois',
      value: 'date',
      width: '25%'
    },
    {
      text: 'Heures',
      value: 'secondsDoneInMonth',
      sortable: false,
      align: 'right',
      width: '25%'
    },
    {
      text: 'Solde heures',
      value: 'secondsBalanceEndOfPreviousMonth',
      sortable: false,
      align: 'right',
      width: '20%'
    },
    {
      text: 'Solde vacances',
      value: 'holidayBalanceEndOfPreviousMonth',
      sortable: false,
      align: 'right',
      width: '20%'
    },
    {
      text: '',
      value: 'validation',
      sortable: false,
      align: 'right',
      width: '5%'
    },
    {
      text: '',
      value: 'edition',
      sortable: false,
      align: 'right',
      width: '2.5%'
    },
    {
      text: '',
      value: 'actions',
      sortable: false,
      align: 'right',
      width: '2.5%'
    }
  ]

  public headersDays = [
    {
      text: 'Jour',
      value: 'day',
      width: '25%'
    },
    {
      text: 'Heures',
      value: 'hours',
      sortable: false,
      align: 'right',
      width: '25%'
    },
    {
      text: 'Solde heures',
      value: 'hoursSup',
      sortable: false,
      align: 'right',
      width: '21.5%'
    },
    {
      text: 'Solde vacances',
      value: 'holidays',
      sortable: false,
      align: 'right',
      width: '21.5%'
    },
    {
      text: '',
      value: 'actions',
      sortable: false,
      align: 'right',
      width: '7%'
    }
  ]

  public editSolde (item: UserMonthHoursResponseModel) {
    this.selectedItem = item
    this.soldeDialog = true
  }

  public closeSoldeDialog () {
    this.selectedItem = {}
    this.soldeDialog = false
    this.search()
  }

  public async mounted () {
    this.layoutService.updateDrawerList(AdministrationHelper.GetAdminNavItems())
    await this.getAllCategoriesWithActivities()
    await this.getAllUsers()
    await this.search()
  }

  private updateReadyState () {
    this.ready = this.readyHours && this.readyCategories && this.readyUsers && !this.validating
  }

  @Watch('searchPatient')
  public searchPatientChanged (v) {
    if (v && v.length > 2) {
      this.refreshPatients()
    } else {
      this.dbPatients = []
    }
  }

  @Watch('readyHours')
  public readyHoursChanged () {
    this.updateReadyState()
  }

  @Watch('readyCategories')
  public readyCategoriesChanged () {
    this.updateReadyState()
  }

  @Watch('readyUsers')
  public readyUsersChanged () {
    this.updateReadyState()
  }

  @Watch('validating')
  public validatingChanged () {
    this.updateReadyState()
  }

  public patientChanged (e) {
    // add the items that aren't yet in selected
    e.forEach(elt => {
      const f = this.selected.find(g => g.guid.toLowerCase() === elt.guid.toLowerCase())
      if (!f) {
        this.selected.push(this.dbPatients.find(g => g.guid.toLowerCase() === elt.guid.toLowerCase())!)
      }
    })
    // remove the items that aren't in e
    this.selected = this.selected.filter(s => e.find(f => f.guid.toLowerCase() === s.guid.toLowerCase()))
  }

  public get patients () {
    return [...this.dbPatients, ...this.selected].sort((a, b) => a.fullName.toLocaleLowerCase().localeCompare(b.fullName.toLocaleLowerCase()))
  }

  public refreshPatients () {
    // bail early if a search is already in progress
    if (!this.readyPatients) {
      return
    }
    this.readyPatients = false
    this.patientsService.getActivePatients(this.searchPatient).then((ds: any) => {
      this.dbPatients = ds.value
    }).catch(async (errs) => {
      const res = await ErrorService.handleError(errs)
      this.errorMessages = res.errors
    }).finally(() => {
      this.readyPatients = true
    })
  }

  public getAllCategoriesWithActivities () {
    this.readyCategories = false
    this.hoursService.getAllCategoriesWithActivities().then((result) => {
      this.rubriques = []
      result.forEach((c, cIdx) => {
        this.rubriques.push({ header: `${1 + cIdx}. ${c.description}` })
        c.activities.forEach((a, aIdx) => {
          this.rubriques.push({ name: `${1 + cIdx}.${1 + aIdx}. ${a.description}`, id: a.id })
        })
        if (cIdx < result.length - 1) {
          this.rubriques.push({ divider: true })
        }
      })
    }).catch(async (errs) => {
      const res = await ErrorService.handleError(errs)
      this.errorMessages = res.errors
    }).finally(() => {
      this.readyCategories = true
    })
  }

  public async getAllUsers () {
    this.readyUsers = false
    await this.authService.getAllUsers().then((users) => {
      this.allUsers = users
    }).catch(async (errs) => {
      const res = await ErrorService.handleError(errs)
      this.errorMessages = res.errors
    }).finally(() => {
      this.readyUsers = true
    })
  }

  public async searchButton () {
    await this.search()
    this.searchModel.page = 1
  }

  public async search () {
    const observer = this.$refs.observer as InstanceType<typeof ValidationObserver>
    const isValid = await observer.validate()
    if (isValid) {
      this.hideAlert()
      this.readyHours = false
      const payload = {
        ...this.searchModel,
        patientIds: this.searchModel.patients?.map(p => p.guid)
      }
      delete payload.patients
      this.hoursService.getAllHoursData(payload).then((result) => {
        this.dataHours = result
        Commons.setRowClasses(this.dataHours.items)
      }).catch(async (errs) => {
        const res = await ErrorService.handleError(errs)
        this.errorMessages = res.errors
      }).finally(() => {
        this.readyHours = true
      })
    } else {
      let merged = this.$refs
      if (this.$refs.DateRef) {
        const dateRefs = (this.$refs.DateRef as Vue).$refs
        merged = Object.assign(merged, dateRefs)
        for (const prop in dateRefs) {
          if (Object.prototype.hasOwnProperty.call(dateRefs, prop)) {
            if (prop.startsWith('DateField')) {
              merged = Object.assign(merged, (dateRefs[prop] as Vue).$refs)
            }
          }
        }
      }
      merged.DateEndRef = merged.DateStartRef = merged['DateField-DateRef']
      Commons.focusFirstComponentWithError(observer, merged, 'Ref')
    }
  }

  private static resetSearchModel () {
    const defaultTo = Commons.TodayDate()
    const defaultFrom = new Date(defaultTo.getUTCFullYear(), defaultTo.getUTCMonth() - 2, defaultTo.getUTCDate())
    const model: HoursSearchModel = {
      page: 1,
      pageSize: 5,
      searchPeriod: {
        from: defaultFrom.toISOString().substr(0, 7),
        to: defaultTo.toISOString().substr(0, 7)
      },
      patients: []
    }
    return model
  }

  public resetSearch () {
    this.searchModel = HoursManage.resetSearchModel()
    this.search()
  }

  public displaySearchPeriodFrom (options: any) {
    return (this.searchPeriodFrom).toLocaleString('fr-CH', options)
  }

  public displaySearchPeriodTo (options: any) {
    return (this.searchPeriodTo).toLocaleString('fr-CH', options)
  }

  public get searchPeriodFrom () {
    return this.searchModel.searchPeriod?.from ? new Date(this.searchModel.searchPeriod.from.trim()) : new Date()
  }

  public get searchPeriodTo () {
    return this.searchModel.searchPeriod?.to ? new Date(this.searchModel.searchPeriod.to.trim()) : new Date()
  }

  public displayDayOfWeekWithDay (date: string) {
    return new Date(date).toLocaleString('fr-CH', { weekday: 'long', day: 'numeric' })
  }

  public getUserName (userId: string) {
    const found = this.allUsers.find(u => u.id.toLowerCase() === userId.toLowerCase())
    if (found) {
      return found.fullName
    }
    return ''
  }

  public getCssGreyOrNothing (isDayOff: boolean) {
    return HoursHelpers.getCssGreyOrNothing(isDayOff ? 1 : 0, 0)
  }

  public getDayCssClass (value: number, threshold: number, isDayOff: boolean) {
    if (isDayOff) {
      return 'grey--text'
    }
    return HoursHelpers.getCssColorClass(value, threshold)
  }

  public getCssColorClass (value, threshold) {
    return HoursHelpers.getCssColorClass(value, threshold)
  }

  public getPlusSign (value) {
    return HoursHelpers.getPlusSign(value)
  }

  public get pageCount () {
    return Math.ceil(this.dataHours.totalSize / this.searchModel.pageSize)
  }

  public getValidateClass (item: UserMonthHoursResponseModel) {
    if (item.validationStatus === 0) {
      return 'action-time'
    } else if (item.validationStatus === 1) {
      return 'green--text'
    } else {
      return 'grey--text text--lighten-1'
    }
  }

  public getValidatedByMessage (item: UserMonthHoursResponseModel) {
    if (item.validationStatus === 0) {
      return 'Valider le mois'
    } else if (item.validationStatus === 1) {
      const user = this.allUsers.find(u => u.id.toLowerCase() === item.validator?.toLowerCase())
      if (user) {
        return `Validé par ${user.initials} (${Commons.TransformDateFormat(item.validationDate)})`
      }
      return 'Validé'
    } else {
      return 'Validation impossible'
    }
  }

  public getViewUserHoursUrl (userId: string, date: string) {
    return `/timbreuse/${userId}/${date.substr(0, 10)}`
  }

  public cancelMonth (monthId?: number) {
    if (!this.validating && !!monthId) {
      this.validating = true
      this.hoursService.invalidateHours(monthId).then(async () => {
        await this.search()
      }).catch(async (errs) => {
        const res = await ErrorService.handleError(errs)
        this.errorMessages = res.errors
      }).finally(() => {
        this.validating = false
      })
    }
  }

  public validateMonth (monthId?: number) {
    if (!this.validating && !!monthId) {
      this.validating = true
      this.hoursService.validateHours(monthId).then(async () => {
        await this.search()
      }).catch(async (errs) => {
        const res = await ErrorService.handleError(errs)
        this.errorMessages = res.errors
      }).finally(() => {
        this.validating = false
      })
    }
  }

  public formatMonthlyDate (item: UserMonthHoursResponseModel) {
    return new Date(item.date).toLocaleString('fr-CH', { year: 'numeric', month: 'long' })
  }

  public hideAlert () {
    this.errorMessages = []
  }

  public get exportErrorHandler () {
    return (e) => {
      Commons.defaultVAuthHrefErrorHandler(this.errorMessages, e)
    }
  }

  public displayPatient (item: dossierPatientModel) {
    return Commons.autocompleteDisplayPatient(item, this.searchPatient)
  }

  public exportAuthRefFinishCallback () {
    // the issue is that v-auth-href will set the text of the link to
    // something like 'downloading...' and then it will reset the text
    // back to what it originally was but the problem is that this is
    // no longer going to be reactive. We could use the dirty, and
    // non-recommended hack to hide and show back the element with a v-if
    // directive but instead we are using a cleaner hack which is to change
    // value of the key of the element and that will also force a refresh
    ++this.textRefreshHack
  }

  public textRefreshHack = 0
}

import http from '@/http-client'
import { PagedResults } from '@/models/common-models'
import { HeuresMoisAnneeEditableReponseModel, HeuresMoisAnneeModel, HeuresReqModel, HeuresResponseModel, HoursSearchRequest, HoursResponseModel, PublicHolidayModel, SoldesInitiauxEditableReponseModel, UserHourConfigurationValues, UserHoursConfigurationModel, UserHoursSoldeInitiauxValues, HourCategory, HeuresCategoryModel } from '@/models/hours-model'

export class HourService {
  private static instance: HourService

  public static getInstance (): HourService {
    if (!HourService.instance) {
      HourService.instance = new HourService()
    }
    return HourService.instance
  }

  // public holidays
  public async getPublicHolidays (year: number) {
    const response = await http.get(`/public-holiday/${year}`)
    return (response.data) as PublicHolidayModel[]
  }

  public async addUpdatePublicHoliday (data: PublicHolidayModel) {
    const response = await http.post('/public-holiday', data)
    return response.data as PublicHolidayModel
  }

  public async deletePublicHoliday (id?: number) {
    if (!id) return 0
    const response = await http.delete(`/public-holiday/${id}`)
    return response.data as number
  }

  // general config
  public async getHoursTable () {
    const response = await http.get('/hours/hours-table')
    return (response.data) as HeuresCategoryModel[]
  }

  // users' hours config
  public async getUserHourConfig () {
    const response = await http.get('/hours/user-hour-config')
    return (response.data) as UserHoursConfigurationModel[]
  }

  public async addUserHourConfig (userId: string, data: UserHourConfigurationValues) {
    const response = await http.post(`/hours/user-hour-config/${userId}`, data)
    return (response.data) as number
  }

  public async updateSoldesInitiaux (userId: string, data: UserHoursSoldeInitiauxValues) {
    const response = await http.post(`/hours/user-hour-config/${userId}/soldes-init`, data)
    return (response.data) as number
  }

  public async areInitialBalanceEditable () {
    const response = await http.get(`/hours/user-hour-config/soldes-init-editable`)
    return (response.data) as SoldesInitiauxEditableReponseModel[]
  }

  // hours per month per year
  public async getHoursPerMonthPerYear () {
    const response = await http.get('/hours/hours-per-month-per-year')
    return (response.data) as HeuresMoisAnneeModel[]
  }

  public async addHoursPerMonthPerYear (data: HeuresMoisAnneeModel) {
    const response = await http.post('/hours/hours-per-month-per-year', data)
    return response.data as number
  }

  public async deleteHoursPerMonthPerYear (itemId?: number) {
    if (!itemId) return 0
    const response = await http.delete(`/hours/hours-per-month-per-year/${itemId}`)
    return response.data as number
  }

  public async areHoursPerMonthPerYearEditable () {
    const response = await http.get(`/hours/hours-per-month-per-year/editable`)
    return (response.data) as HeuresMoisAnneeEditableReponseModel[]
  }

  // hours (manual input)
  public async getUserHours (date: string, userId?: string) {
    const response = await http.get(`/hours?date=${date}&userId=${userId ?? ''}`)
    return (response.data) as HeuresResponseModel
  }

  public async updateUserHours (data: HeuresReqModel, userId?: string) {
    const response = await http.post(`/hours?userId=${userId ?? ''}`, data)
    return response.data as number
  }

  public async deleteUserHour (itemId?: number) {
    if (!itemId) return 0
    const response = await http.delete(`/hours/${itemId}`)
    return response.data as number
  }

  // hours admin
  public async getAllHoursData (searchModel: HoursSearchRequest) {
    const response = await http.post('/hours/search', searchModel)
    return (response.data) as PagedResults<HoursResponseModel>
  }

  public async getAllCategoriesWithActivities () {
    const response = await http.get('/hours/category')
    return response.data as HourCategory[]
  }

  public async validateHours (heuresMoisId: number) {
    const response = await http.put('/hours/validate', heuresMoisId)
    return response.data as number
  }

  public async invalidateHours (heuresMoisId: number) {
    const response = await http.put('/hours/invalidate', heuresMoisId)
    return response.data as number
  }

  public async editMonthSolde (monthId?: number, soldeHeures?: string, soldeVacances?: string, commentHeures?: string, commentVacances?: string) {
    if (monthId) {
      const response = await http.post(`/hours/editBalance/${monthId}`, { soldeHeures, soldeVacances, commentHeures, commentVacances })
      return response.data as number
    }
    return 0
  }
}

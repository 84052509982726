export default class HoursHelpers {
  public static getCssColorClass (value: number, threshold: number) {
    return value >= threshold ? 'green--text' : 'primary--text'
  }

  public static getCssPrimaryOrNothing (value: number, threshold: number) {
    return HoursHelpers.getCssClassOrNothing(value, threshold, 'primary--text')
  }

  public static getCssGreyOrNothing (value: number, threshold: number) {
    return HoursHelpers.getCssClassOrNothing(value, threshold, 'grey--text')
  }

  public static getCssClassOrNothing (value: number, threshold: number, className: string) {
    return value > threshold ? className : ''
  }

  public static getPlusSign (value: number) {
    if (value > 0) {
      return '+'
    }
    return ''
  }
}

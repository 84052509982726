import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { ValidationObserver } from 'vee-validate'
import Commons from '../../Helpers/commons'
import { UserMonthHoursResponseModel } from '@/models/hours-model'
import { HourService } from '@/services/hour-service'
import { ErrorService } from '@/services/error.service'
import Alert from '@/components/shared/Alert.vue'

@Component({
  components: {
    Alert,
    ValidationObserver
  }
})

export default class SoldeDialog extends Vue {
  private hoursService = HourService.getInstance()
  @Prop()
  public visible!: boolean

  @Prop()
  public item!: UserMonthHoursResponseModel

  public soldeVacances = ''
  public soldeHeures = ''
  public commentHeures = ''
  public commentVacances = ''
  public saving = false
  public errorMessages: string[] = []

  get show () {
    return this.visible
  }

  public reset () {
    this.soldeHeures = ''
    this.soldeVacances = ''
    this.commentHeures = ''
    this.commentVacances = ''
    const observer = this.$refs.observer as InstanceType<typeof ValidationObserver>
    if (observer) {
      observer.reset()
    }
  }

  public emitClose () {
    this.reset()
    this.hideAlert()
    this.$emit('close')
  }

  public async save () {
    const observer = this.$refs.observer as InstanceType<typeof ValidationObserver>
    const isValid = observer && await observer.validate()
    if (isValid && !!this.item.heureMoisId) {
      this.saving = true
      this.hoursService.editMonthSolde(this.item.heureMoisId, this.soldeHeures, this.soldeVacances, this.commentHeures, this.commentVacances)
        .then(() => {
          this.emitClose()
        }).catch(async (errs) => {
          const res = await ErrorService.handleError(errs)
          this.errorMessages = res.errors
        }).finally(() => {
          this.saving = false
        })
    } else {
      Commons.focusFirstComponentWithError(observer, this.$refs, 'Ref')
    }
  }

  public timeMask (value) {
    return Commons.timeMask(value)
  }

  public hideAlert () {
    this.errorMessages = []
  }
}
